import React from "react";
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";

import {
  TextInputField,
  Badge,
  Button,
  Card,
  Heading,
  IconButton,
  majorScale,
  Menu,
  Pane,
  PauseIcon,
  Tab,
  Tablist,
  AddIcon,
  Dialog,
  SearchInput,
  toaster,
  TextareaField,
  TabNavigation,
  Icon,
} from "evergreen-ui";
import Stores from "./Stores";
import ProductCategory from "./ProductCategory";
import ProductGroups from "./ProductGroups";
import * as Yup from "yup";
import { useFormik } from "formik";

import axios from "axios";
import { checkTokenLogout } from "../auth";

export function Ecommerce() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showModalAddStore, setShowModalAddStore] = useState(false);
  const toggleModalAddStore = () => setShowModalAddStore(!showModalAddStore);

  const history = useHistory("");
  const token = localStorage.getItem("token");
  const [isReload, setIsReload] = useState(false);
  const tabImport = {
    0: "store",
    1: "product_category",
    2: "product_groups",
  };
  const [selectedOpStore_type, setSelectedOpStore_type] = useState();

  const handleChangeStore_type = (options) => {
    setSelectedOpStore_type(options);
  };
  const [selectedOpStatus, setSelectedOpStatus] = useState();

  const handleChange = (options) => {
    setSelectedOpStatus(options);
  };
  const [selectedOpCurrency, setSelectedOpCurrency] = useState();

  const handleChangeCurrency = (options) => {
    setSelectedOpCurrency(options);
  };

  const [selectedOpPGroup, setSelectedOpPGroup] = useState();

  const handleChangePGroup = (options) => {
    setSelectedOpPGroup(options);
  };

  const changeCpnImport = (key) => {
    switch (tabImport[key]) {
      case "store":
        return (
          <Stores
            loadChild={isReload}
            setShowDialog={setShowModalAddStore}
          ></Stores>
        );
      case "product_category":
        return (
          <ProductCategory
            loadChild={isReload}
            setShowDialog={setShowModalAddCategory}
          ></ProductCategory>
        );
      case "product_groups":
        return (
          <ProductGroups
            loadChild={isReload}
            setShowDialog={setShowModalAddPGroup}
          ></ProductGroups>
        );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      // store_type: "",
      // currency: "",
      // status: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập tên gian hàng"),
      // store_type: Yup.string()
      //   .required("Vui lòng chọn loại gian hàng"),
      // currency: Yup.string()
      //   .required("Vui lòng chọn tiền tệ"),
      // status: Yup.string()
      //   .required("Vui lòng chọn trạng thái"),
    }),
    onSubmit: (values) => {
      const url = "http://v2.nextify.vn/marketing/stores";
      const method = "POST";
      const headers = {
        Authorization: "Bearer " + token,
      };

      var bodyFormData = new FormData();
      bodyFormData.append("name", values.name.trim());
      // bodyFormData.append("quantity", values.quantity);
      bodyFormData.append("status", selectedOpStatus.value);
      bodyFormData.append("store_type", selectedOpStore_type.value);
      bodyFormData.append("currency", selectedOpCurrency.value);

      axios({
        method: method,
        url: url,
        headers: headers,
        data: bodyFormData,
      })
        .then((res) => {
          const j_response = res.data;
          var code = j_response.code;
          if (code == 200) {
            formik.values.name = "";
            // formik.values.status = "";
            // formik.values.store_type = "";
            // formik.values.currency = "";

            // formik.values.quantity = "";

            setShowModalAddStore();
            setIsReload(!isReload);
            toaster.success("Thêm gian hàng thành công");
          } else {
            toaster.warning("Thêm gian hàng thất bại");
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 401) {
            checkTokenLogout(history);
          } else if (status == 422) {
            toaster.warning("Vui lòng điền đầy đủ thông tin");
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    },
  });

  const [showModalAddCategory, setShowModalAddCategory] = useState(false);
  const toggleModalAddCategory = () =>
    setShowModalAddCategory(!showModalAddCategory);

  const [showModalAddPGroup, setShowModalAddPGroup] = useState(false);
  const toggleModalAddPGroup = () => setShowModalAddPGroup(!showModalAddPGroup);

  const formikAdd = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập tên loại sản phẩm"),
      description: Yup.string().trim(),
    }),
    onSubmit: (values) => {
      const url = "http://v2.nextify.vn/categories";
      const method = "POST";
      const headers = {
        Authorization: "Bearer " + token,
      };

      var bodyFormData = new FormData();
      bodyFormData.append("name", values.name.trim());
      bodyFormData.append("description", values.description.trim());

      axios({
        method: method,
        url: url,
        headers: headers,
        data: bodyFormData,
      })
        .then((res) => {
          const j_response = res.data;
          var code = j_response.code;
          if (code == 200) {
            toaster.success("Thêm loại sản phẩm thành công");
            formikAdd.values.name = "";
            formikAdd.values.description = "";

            toggleModalAddCategory();
            setIsReload(!isReload);
          } else {
            let msg = j_response.msg;
            toaster.danger(msg, {
              position: "bottom-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 401) {
            checkTokenLogout(history);
          } else if (status == 422) {
            toaster.warning("Vui lòng điền đầy đủ thông tin");
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    },
  });

  const formikPGroup = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      parent_id: "",
      name: "",
      slug: "",
      // status: "",
      type: "",
    },
    validationSchema: Yup.object({
      id: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập mã nhóm sản phẩm"),
      parent_id: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập nhóm cha"),
      name: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập tên nhóm sản phẩm"),
      slug: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập slug của tên"),
      // status: Yup.string()
      //   .required("Vui lòng chọn trạng thái nhóm sản phẩm"),
      type: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập loại nhóm sản phẩm"),
    }),
    onSubmit: (values) => {
      const url = "http://v2.nextify.vn/product_groups";
      const method = "POST";
      const headers = {
        Authorization: "Bearer " + token,
      };

      var bodyFormData = new FormData();
      bodyFormData.append("id", values.id.trim());
      bodyFormData.append("parent_id", values.parent_id.trim());
      bodyFormData.append("name", values.name.trim());
      bodyFormData.append("slug", values.slug.trim());
      bodyFormData.append("status", selectedOpPGroup.value);
      bodyFormData.append("type", values.type.trim());

      axios({
        method: method,
        url: url,
        headers: headers,
        data: bodyFormData,
      })
        .then((res) => {
          const j_response = res.data;
          var code = j_response.code;
          if (code == 200) {
            toaster.success("Thêm nhóm sản phẩm thành công");
            formikAdd.values.id = "";
            formikAdd.values.parent_id = "";
            formikAdd.values.name = "";
            formikAdd.values.slug = "";
            // formikAdd.values.status = "";
            formikAdd.values.type = "";

            toggleModalAddPGroup();
            setIsReload(!isReload);
          } else {
            let msg = j_response.msg;
            toaster.danger(msg, {
              position: "bottom-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 401) {
            checkTokenLogout(history);
          } else if (status == 422) {
            toaster.warning("Vui lòng điền đầy đủ thông tin");
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    },
  });

  return (
    <div>
      {selectedIndex === 0 ? (
        <>
          <Dialog
            isShown={showModalAddStore}
            title="Thêm mới gian hàng"
            onCloseComplete={() => setShowModalAddStore(false)}
            width={850}
            hasFooter={false}
          >
            <form action="#/" onSubmit={formik.handleSubmit} id="form_add">
              <Pane>
                <div className="form-row">
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="nameAdd">
                      Tên gian hàng (<span className="text-danger">*</span>)
                    </label>
                    <TextInputField
                      label=""
                      inputHeight={40}
                      placeholder="Nhập tên gian hàng"
                      type="text"
                      name="name"
                      id="validationCustom01"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      validationMessage={
                        formik.touched.name && formik.errors.name
                          ? formik.errors.name
                          : false
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="store_typeAdd">
                      Loại gian hàng
                    </label>
                    <Pane marginTop={13} marginBottom={24}>
                      <Select
                        placeholder="Chọn loại gian hàng"
                        id="store_typeAdd"
                        name="store_type"
                        onChange={handleChangeStore_type}
                        onBlur={formik.handleBlur}
                        // defaultValue={selectedOpCurrency}
                        options={[
                          { value: "Thủ công", label: "Thủ công" },
                          { value: "KiotViet", label: "KiotViet" },
                          { value: "Haravan", label: "Haravan" },
                          { value: "Nhanh.vn", label: "Nhanh.vn" },
                        ]}
                        menuPlacement="auto"
                        styles={{
                          menuPortal: (base) => {
                            const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                            return { ...rest, zIndex: 9999 };
                          },
                        }}
                        menuPortalTarget={document.body}
                        isSearchable
                      />
                    </Pane>
                    {formik.touched.store_type && formik.errors.store_type ? (
                      <div className="valid_feedback">
                        <svg viewBox="0 0 16 16">
                          <path
                            d="M7.99-.01c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-3h-2v-7h2v7z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                        <p>{formik.errors.store_type}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="currencyAdd">
                      Đơn vị tiền
                    </label>
                    <Pane marginTop={13} marginBottom={24}>
                      <Select
                        placeholder="Chọn đơn vị tiền"
                        id="currencyAdd"
                        name="currency"
                        onChange={handleChangeCurrency}
                        onBlur={formik.handleBlur}
                        // defaultValue={selectedOpCurrency}
                        options={[
                          { value: "VND", label: "VND" },
                          { value: "USD", label: "USD" },
                        ]}
                        menuPlacement="auto"
                        styles={{
                          menuPortal: (base) => {
                            const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                            return { ...rest, zIndex: 9999 };
                          },
                        }}
                        menuPortalTarget={document.body}
                        isSearchable
                      />
                    </Pane>
                    {formik.touched.currency && formik.errors.currency ? (
                      <div className="valid_feedback">
                        <svg viewBox="0 0 16 16">
                          <path
                            d="M7.99-.01c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-3h-2v-7h2v7z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                        <p>{formik.errors.currency}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="statusAdd">
                      Trạng thái
                    </label>
                    <Pane marginTop={13} marginBottom={24}>
                      <Select
                        placeholder="Chọn trạng thái"
                        id="statusAdd"
                        name="status"
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        // defaultValue={selectedOpCurrency}
                        options={[
                          { value: "Đang hoạt động", label: "Đang hoạt động" },
                          {
                            value: "Không hoạt động",
                            label: "Không hoạt động",
                          },
                        ]}
                        menuPlacement="auto"
                        styles={{
                          menuPortal: (base) => {
                            const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                            return { ...rest, zIndex: 9999 };
                          },
                        }}
                        menuPortalTarget={document.body}
                        isSearchable
                      />
                    </Pane>
                    {formik.touched.status && formik.errors.status ? (
                      <div className="valid_feedback">
                        <svg viewBox="0 0 16 16">
                          <path
                            d="M7.99-.01c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-3h-2v-7h2v7z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                        <p>{formik.errors.status}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Pane>

              <Pane display="flex" alignItems="right" justifyContent="right">
                <Button
                  type="submit"
                  appearance="primary"
                  marginTop={20}
                  height={40}
                >
                  Thêm mới
                </Button>
              </Pane>
            </form>
          </Dialog>{" "}
        </>
      ) : selectedIndex === 1 ? (
        <>
          <Dialog
            isShown={showModalAddCategory}
            title="Thêm mới loại sản phẩm"
            onCloseComplete={() => setShowModalAddCategory(false)}
            width={850}
            hasFooter={false}
          >
            <form action="#/" onSubmit={formikAdd.handleSubmit}>
              <Pane>
                <label className="form-label" htmlFor="nameAdd">
                  Tên loại sản phẩm (<span className="text-danger">*</span>)
                </label>
                <TextInputField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập tên loại sản phẩm"
                  type="text"
                  name="name"
                  id="nameAdd"
                  onChange={formikAdd.handleChange}
                  onBlur={formikAdd.handleBlur}
                  value={formikAdd.values.name}
                  validationMessage={
                    formikAdd.touched.name && formikAdd.errors.name
                      ? formikAdd.errors.name
                      : false
                  }
                />
                <label className="form-label" htmlFor="descriptionAdd">
                  Mô tả
                </label>
                <TextareaField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập mô tả"
                  type="text"
                  name="description"
                  id="descriptionAdd"
                  onChange={formikAdd.handleChange}
                  onBlur={formikAdd.handleBlur}
                  value={formikAdd.values.description}
                  validationMessage={
                    formikAdd.touched.description &&
                    formikAdd.errors.description
                      ? formikAdd.errors.description
                      : false
                  }
                />
              </Pane>
              <Pane display="flex" alignItems="right" justifyContent="right">
                <Button
                  type="submit"
                  appearance="primary"
                  marginTop={20}
                  height={40}
                >
                  Thêm mới
                </Button>
              </Pane>
            </form>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            isShown={showModalAddPGroup}
            title="Thêm mới nhóm sản phẩm"
            onCloseComplete={() => setShowModalAddPGroup(false)}
            width={850}
            hasFooter={false}
          >
            <form action="#/" onSubmit={formikPGroup.handleSubmit}>
              <Pane>
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="idAdd">
                      Mã nhóm sản phẩm (<span className="text-danger">*</span>)
                    </label>
                    <TextInputField
                      label=""
                      inputHeight={40}
                      placeholder="Nhập mã nhóm sản phẩm"
                      type="text"
                      name="id"
                      id="idAdd"
                      onChange={formikPGroup.handleChange}
                      onBlur={formikPGroup.handleBlur}
                      value={formikPGroup.values.id}
                      validationMessage={
                        formikPGroup.touched.id && formikPGroup.errors.id
                          ? formikPGroup.errors.id
                          : false
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="parent_idAdd">
                      ID nhóm cha (<span className="text-danger">*</span>)
                    </label>
                    <TextInputField
                      label=""
                      inputHeight={40}
                      placeholder="Nhập ID nhóm cha"
                      type="text"
                      name="parent_id"
                      id="parent_idAdd"
                      onChange={formikPGroup.handleChange}
                      onBlur={formikPGroup.handleBlur}
                      value={formikPGroup.values.parent_id}
                      validationMessage={
                        formikPGroup.touched.parent_id &&
                        formikPGroup.errors.parent_id
                          ? formikPGroup.errors.parent_id
                          : false
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="nameAdd">
                      Tên nhóm sản phẩm (<span className="text-danger">*</span>)
                    </label>
                    <TextInputField
                      label=""
                      inputHeight={40}
                      placeholder="Nhập tên nhóm sản phẩm"
                      type="text"
                      name="name"
                      id="nameAdd"
                      onChange={formikPGroup.handleChange}
                      onBlur={formikPGroup.handleBlur}
                      value={formikPGroup.values.name}
                      validationMessage={
                        formikPGroup.touched.name && formikPGroup.errors.name
                          ? formikPGroup.errors.name
                          : false
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="slugAdd">
                      Slug của tên (<span className="text-danger">*</span>)
                    </label>
                    <TextInputField
                      label=""
                      inputHeight={40}
                      placeholder="Nhập Slug của tên"
                      type="text"
                      name="slug"
                      id="slugAdd"
                      onChange={formikPGroup.handleChange}
                      onBlur={formikPGroup.handleBlur}
                      value={formikPGroup.values.slug}
                      validationMessage={
                        formikPGroup.touched.slug && formikPGroup.errors.slug
                          ? formikPGroup.errors.slug
                          : false
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="statusAdd">
                      Trạng thái (<span className="text-danger">*</span>)
                    </label>
                    <Pane marginTop={13} marginBottom={24}>
                      <Select
                        placeholder="Chọn trạng thái"
                        id="statusAdd"
                        name="status"
                        onChange={handleChangePGroup}
                        onBlur={formikPGroup.handleBlur}
                        // defaultValue={selectedOpCurrency}
                        options={[
                          { value: "Đang hoạt động", label: "Đang hoạt động" },
                          {
                            value: "Không hoạt động",
                            label: "Không hoạt động",
                          },
                        ]}
                        menuPlacement="auto"
                        styles={{
                          menuPortal: (base) => {
                            const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                            return { ...rest, zIndex: 9999 };
                          },
                        }}
                        menuPortalTarget={document.body}
                        isSearchable
                      />
                    </Pane>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="typeAdd">
                      Loại nhóm sản phẩm (<span className="text-danger">*</span>
                      )
                    </label>
                    <TextInputField
                      label=""
                      inputHeight={40}
                      placeholder="Nhập loại nhóm sản phẩm"
                      type="text"
                      name="type"
                      id="typeAdd"
                      onChange={formikPGroup.handleChange}
                      onBlur={formikPGroup.handleBlur}
                      value={formikPGroup.values.type}
                      validationMessage={
                        formikPGroup.touched.type && formikPGroup.errors.type
                          ? formikPGroup.errors.type
                          : false
                      }
                    />
                  </div>
                </div>
              </Pane>
              <Pane display="flex" alignItems="right" justifyContent="right">
                <Button
                  type="submit"
                  appearance="primary"
                  marginTop={20}
                  height={40}
                >
                  Thêm mới
                </Button>
              </Pane>
            </form>
          </Dialog>
        </>
      )}

      <div className="container ">
        <div className="az-content-body">
          <div className="az-dashboard-one-title">
            <div>
              <Heading>Gian hàng</Heading>
              <p className="az-dashboard-text">
                Quản lý gian hàng và sản phẩm.
              </p>
            </div>
            {selectedIndex === 0 ? (
              <div className="az-content-header-right">
                <IconButton
                  icon={AddIcon}
                  onClick={toggleModalAddStore}
                  width={40}
                  className="btn btn-primary btn-icon"
                ></IconButton>
              </div>
            ) : selectedIndex === 1 ? (
              <div className="az-content-header-right">
                <IconButton
                  icon={AddIcon}
                  onClick={toggleModalAddCategory}
                  width={40}
                  className="btn btn-primary btn-icon"
                ></IconButton>
              </div>
            ) : (
              <div className="az-content-header-right">
                <IconButton
                  icon={AddIcon}
                  onClick={toggleModalAddPGroup}
                  width={40}
                  className="btn btn-primary btn-icon"
                ></IconButton>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <TabNavigation>
                {["Gian hàng", "Loại sản phẩm", "Nhóm sản phẩm"].map(
                  (tab, index) => (
                    <Tab
                      key={tab}
                      id={tab}
                      onSelect={() => setSelectedIndex(index)}
                      isSelected={index === selectedIndex}
                      aria-controls={`panel-${tab}`}
                    >
                      {tab}
                    </Tab>
                  )
                )}
              </TabNavigation>
              <hr />

              {["Gian hàng", "Loại sản phẩm", "Nhóm sản phẩm"].map(
                (tab, index) => (
                  <Pane
                    key={tab}
                    id={`panel-${tab}`}
                    role="tabpanel"
                    aria-labelledby={tab}
                    aria-hidden={index !== selectedIndex}
                    display={index === selectedIndex ? "block" : "none"}
                  >
                    {changeCpnImport(selectedIndex)}
                  </Pane>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Ecommerce;
