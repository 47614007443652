import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ViewContactType from "./customers/ViewContactType";
import Ecommerce from "./marketing/Ecommerce";

import Loading from "./ui-elements/Loading";
import { isAuth } from "./auth";

const Start = lazy(() => import("./shared/Overview"));
const Home = lazy(() => import("./shared/Home"));

const Customers = lazy(() => import("./customers/ListCustomers"));
const ListCustomers = lazy(() => import("./customers/ListCustomers"));
const AddCustomers = lazy(() => import("./customers/AddCustomers"));
const ViewImportCustomers = lazy(() =>
  import("./customers/ViewImportCustomers")
);
const ListCompany = lazy(() => import("./customers/ListCompany"));
const Groups = lazy(() => import("./customers/ListGroups"));
// const ImportCustomers = lazy(() => import('./customers/ImportCustomers'))
const Types = lazy(() => import("./contacts/types/Types"));
const CustomField = lazy(() => import("./customers/ViewCustomField"));
const ContactType = lazy(() => import("./customers/ViewContactType"));
const LoyaltyRank = lazy(() => import("./customers/ViewLoyaltyRank"));
const ViewTags = lazy(() => import("./customers/ViewTags"));
const CustomerItem = lazy(() => import("./customers/CustomerDetail"));
const Pipeline = lazy(() => import("./stores/Pipeline"));
const Invoice = lazy(() => import("./stores/Invoice"));
const Quotes = lazy(() => import("./stores/Quotes"));
const Appointments = lazy(() => import("./stores/Appointments"));
const Booking = lazy(() => import("./stores/Booking"));

const Tasks = lazy(() => import("./tasks/Tasks"));
const ImportTasks = lazy(() => import("./tasks/ImportTasks"));
const EditTasks = lazy(() => import("./tasks/EditTasks"));

const Stores = lazy(() => import("./marketing/Ecommerce"));
const EcomConfig = lazy(() => import("./marketing/EcomConfig"));
const ImportStores = lazy(() => import("./marketing/ImportStores"));
const Products = lazy(() => import("./marketing/Product"));
const Orders = lazy(() => import("./marketing/Orders"));
const Automation = lazy(() => import("./marketing/Automation"));
const AutomationBuilder = lazy(() => import("./marketing/AutomationBuilder"));
const AutomationEdit = lazy(() => import("./marketing/AutomationEdit"));
const AutomationEvent = lazy(() => import("./marketing/AutomationEvent"));

const Campaigns = lazy(() => import("./marketing/Campaigns"));
const CampaignEmailConfig = lazy(() =>
  import("./marketing/CampaignEmailConfig")
);
const CampaignEmailEdit = lazy(() => import("./marketing/CampaignEmailEdit"));

const Templates = lazy(() => import("./marketing/Templates"));
const Pages = lazy(() => import("./marketing/Pages"));
const Signin = lazy(() => import("./general-pages/Signin"));
const Signup = lazy(() => import("./general-pages/Signup"));

const Buttons = lazy(() => import("./ui-elements/Buttons"));
const Dropdowns = lazy(() => import("./ui-elements/Dropdowns"));
const Icons = lazy(() => import("./ui-elements/Icons"));
const FormElements = lazy(() => import("./form/FormElements"));
const ChartJs = lazy(() => import("./charts/ChartJs"));
const BasicTable = lazy(() => import("./tables/BasicTable"));

const Tags = lazy(() => import("./tags/Tags"));

const EmailEditor = lazy(() => import("./templates/EmailEditorBuilder"));
const EmailEditorEdit = lazy(() => import("./templates/EmailEditorEdit"));

const Integrations = lazy(() => import("./general-pages/Integrations.js"));
const IntegrationItem = lazy(() => import("./general-pages/IntegrationItem"));
const IntegrationMobileApp = lazy(() =>
  import("./general-pages/IntegrationMobileApp")
);

const Profile = lazy(() => import("./general-pages/Profile.js"));
const Activity = lazy(() => import("./general-pages/Activity.js"));
const Dashboard = lazy(() => import("./general-pages/Dashboard.js"));
const OrderItem = lazy(() => import("./stores/OrderItem.js"));

const ProductStores = lazy(() => import("./marketing/ProductStores"));
const Call = lazy(() => import("./call/TestDial"));
const EcommerceCDN = lazy(() => import("./marketing/EcommerceCDN"));
const Employee = lazy(() => import("./employee/Employee"));

const ReportContacts = lazy(() => import("./reports/ReportContacts"));
const ReportMarketing = lazy(() => import("./reports/Marketing"));
const ReportSales = lazy(() => import("./reports/Sales"));
const ReportOrders = lazy(() => import("./reports/ReportOrders"));
const BytedanceFlow = lazy(() => import("./utilities/BytedanceFlow"));

export class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Loading height="70vh"></Loading>}>
        <Switch>
          <Route exact path="/home/start" component={Start}>
            {isAuth() ? <Start></Start> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/home/dashboard" component={Start}>
            {isAuth() ? <Start></Start> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/contacts" component={ListCustomers}>
            {isAuth() ? (
              <ListCustomers></ListCustomers>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/add" component={AddCustomers}>
            {isAuth() ? (
              <AddCustomers></AddCustomers>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/import" component={ViewImportCustomers}>
            {isAuth() ? (
              <ViewImportCustomers></ViewImportCustomers>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/customers" component={Customers}>
            {isAuth() ? (
              <Customers></Customers>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/customers/:id" component={CustomerItem}>
            {isAuth() ? (
              <CustomerItem></CustomerItem>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/groups" component={Groups}>
            {isAuth() ? <Groups></Groups> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/home/start" component={Home} />
          <Route exact path="/home/dashboard" component={Home} />
          <Route exact path="/contacts" component={ListCustomers} />
          <Route exact path="/contacts/add" component={AddCustomers} />
          <Route exact path="/contacts/import" component={ViewImportCustomers} />
          <Route exact path="/contacts/customers" component={Customers} />
          <Route exact path="/contacts/customers/:id" component={CustomerItem} />
          <Route exact path="/contacts/groups" component={Groups} />
          {/* <Route exact path="/contacts/tags" component={Tags} /> */}
          <Route exact path="/contacts/companies" component={ListCompany}>
            {isAuth() ? (
              <ListCompany></ListCompany>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/types" component={Types}>
            {isAuth() ? <Types></Types> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/contacts/custom-fields" component={CustomField}>
            {isAuth() ? (
              <CustomField></CustomField>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route
            exact
            path="/contacts/contact-types"
            component={ViewContactType}
          >
            {isAuth() ? (
              <ViewContactType></ViewContactType>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/ranks" component={LoyaltyRank}>
            {isAuth() ? (
              <LoyaltyRank></LoyaltyRank>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/contacts/tags" component={ViewTags}>
            {isAuth() ? (
              <ViewTags></ViewTags>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/pipeline" component={Pipeline}>
            {isAuth() ? (
              <Pipeline></Pipeline>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/stores/products" component={Products}>
            {isAuth() ? (
              <Products></Products>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/quotes" component={Quotes}>
            {isAuth() ? <Quotes></Quotes> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/appointments" component={Appointments}>
            {isAuth() ? (
              <Appointments></Appointments>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/invoices" component={Invoice}>
            {isAuth() ? (
              <Invoice></Invoice>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/tasks" component={Tasks}>
            {isAuth() ? <Tasks></Tasks> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/tasks/import" component={ImportTasks}>
            {isAuth() ? (
              <ImportTasks></ImportTasks>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/tasks/:id" component={EditTasks}>
            {isAuth() ? (
              <EditTasks></EditTasks>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/ecommerce/stores" component={Stores}>
            {isAuth() ? <Stores></Stores> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/ecommerce/config" component={EcomConfig}>
            {isAuth() ? (
              <EcomConfig></EcomConfig>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/ecommerce/stores/import" component={ImportStores}>
            {isAuth() ? (
              <ImportStores></ImportStores>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/ecommerce/stores/:id" component={Products}>
            {isAuth() ? (
              <Products></Products>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/ecommerce/orders" component={Orders}>
            {isAuth() ? <Orders></Orders> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/ecommerce/orders/:id" component={OrderItem}>
            {isAuth() ? (
              <OrderItem></OrderItem>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/automation" component={Automation}>
            {isAuth() ? (
              <Automation></Automation>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/automation/builder" component={AutomationBuilder}>
            {isAuth() ? (
              <AutomationBuilder></AutomationBuilder>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/automation/new" component={AutomationEvent}>
            {isAuth() ? (
              <AutomationEvent></AutomationEvent>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/automation/edit/:id" component={AutomationEdit}>
            {isAuth() ? (
              <AutomationEdit></AutomationEdit>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/campaigns" component={Campaigns}>
            {isAuth() ? (
              <Campaigns></Campaigns>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/campaigns/email" component={CampaignEmailConfig}>
            {isAuth() ? (
              <CampaignEmailConfig></CampaignEmailConfig>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route
            exact
            path="/campaigns/email/:id"
            component={CampaignEmailEdit}
          >
            {isAuth() ? (
              <CampaignEmailEdit></CampaignEmailEdit>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/templates" component={Templates}>
            {isAuth() ? (
              <Templates></Templates>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/pages" component={Pages}>
            {isAuth() ? <Pages></Pages> : <Redirect to="/signin"></Redirect>}
          </Route>

          <Route exact path="/signin" component={Signin}>
            {isAuth() ? (
              <Redirect to="/home/dashboard"></Redirect>
            ) : (
              <Signin></Signin>
            )}
          </Route>
          <Route exact path="/signup" component={Signup} />

          <Route exact path="/ui-elements/buttons" component={Buttons}>
            {isAuth() ? (
              <Buttons></Buttons>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/ui-elements/dropdowns" component={Dropdowns}>
            {isAuth() ? (
              <Dropdowns></Dropdowns>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/ui-elements/icons" component={Icons}>
            {isAuth() ? <Icons></Icons> : <Redirect to="/signin"></Redirect>}
          </Route>

          <Route exact path="/form/form-elements" component={FormElements}>
            {isAuth() ? (
              <FormElements></FormElements>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/charts/chartjs" component={ChartJs}>
            {isAuth() ? (
              <ChartJs></ChartJs>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/tables/basic-table" component={BasicTable}>
            {isAuth() ? (
              <BasicTable></BasicTable>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/email/builder" component={EmailEditor}>
            {isAuth() ? (
              <EmailEditor></EmailEditor>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/email/edit/:id" component={EmailEditorEdit}>
            {isAuth() ? (
              <EmailEditorEdit></EmailEditorEdit>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/integrations" component={Integrations}>
            {isAuth() ? (
              <Integrations></Integrations>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          {/* <Route exact path="/integrations/:type" component={IntegrationItem} /> */}
          <Route
            exact
            path="/integrations/mobileapp"
            component={IntegrationMobileApp}
          >
            {isAuth() ? (
              <IntegrationMobileApp></IntegrationMobileApp>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route exact path="/profile" component={Profile}>
            {isAuth() ? (
              <Profile></Profile>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/activity" component={Activity}>
            {isAuth() ? (
              <Activity></Activity>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/ecommerce/cdn" component={EcommerceCDN}>
            {isAuth() ? (
              <EcommerceCDN></EcommerceCDN>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route
            exact
            path="/ecommerce/categories/:id"
            component={ProductStores}
          >
            {isAuth() ? (
              <ProductStores></ProductStores>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/call" component={Call}>
            {isAuth() ? <Call></Call> : <Redirect to="/signin"></Redirect>}
          </Route>
          <Route exact path="/employee" component={Employee}>
            {isAuth() ? (
              <Employee></Employee>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/reports/contacts" component={ReportContacts}>
            {isAuth() ? (
              <ReportContacts></ReportContacts>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/reports/marketing" component={ReportMarketing}>
            {isAuth() ? (
              <ReportMarketing></ReportMarketing>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/reports/sales" component={ReportSales}>
            {isAuth() ? (
              <ReportSales></ReportSales>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/reports/orders" component={ReportOrders}>
            {isAuth() ? (
              <ReportOrders></ReportOrders>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>
          <Route exact path="/test_flow" component={BytedanceFlow}>
            {isAuth() ? (
              <BytedanceFlow></BytedanceFlow>
            ) : (
              <Redirect to="/signin"></Redirect>
            )}
          </Route>

          <Route path="*">
            <Redirect to="/home/dashboard"></Redirect>
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
