import React from "react";
import { Pagination, Pane } from "evergreen-ui";

const PagingCustom = ({ page, setPage, pageSize, total }) => {
  // phan trang
  const calTotalPage = (pageSize, total) => {
    var result =
      total % pageSize === 0
        ? total / pageSize
        : Math.floor(total / pageSize) + 1;
    return result;
  };

  const handleChangePage = (value) => {
    setPage(value);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };
  //

  return (
    <Pane>
      {total > pageSize ? (
        <Pane
          display="flex"
          justifyContent="center"
          justifyItems="center"
          marginTop={24}
          marginBottom={24}
        >
          <Pagination
            page={page}
            totalPages={calTotalPage(pageSize, total)}
            onPageChange={handleChangePage}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          ></Pagination>
        </Pane>
      ) : (
        <Pane></Pane>
      )}
    </Pane>
  );
};

export default PagingCustom;
