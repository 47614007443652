export const isAuth = () => {
  const token = localStorage.getItem("token");
  var checkAuth = true;

  if (!token) {
    return false;
  }

  return checkAuth;
};

export const checkTokenLogout = (history) => {
  history.push("/signin");
  localStorage.clear();
};
