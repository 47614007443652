import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
// import Start from './shared/Overview';

import { ThemeProvider } from "@chakra-ui/core";
import { CSSReset, theme } from "@chakra-ui/core";

import { store, persistor } from "./models";
import { StoreProvider } from "easy-peasy";
import { PersistGate } from "redux-persist/integration/react";

class App extends Component {
  state = {};
  componentDidMount() {
    this.onRouteChanged();
  }
  render() {
    let headerComponent = !this.state.isFullPageLayout ? <Header /> : "";
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <div>
        {headerComponent}
        <div className="az-content-wrapper">
          <StoreProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <AppRoutes />
              </ThemeProvider>
            </PersistGate>
          </StoreProvider>
        </div>
        {footerComponent}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/signin",
      "/signup",
      "/general-pages/page-404",
      "/automation/builder",
      "/test_flow",
      "/automation/edit",
      "/email/builder",
      "/email/edit",
      "/marketing/templates/email",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      let location = this.props.location.pathname;
      if (
        location === fullPageLayoutRoutes[i] ||
        location.includes("/automation/edit/") ||
        location.includes("/email/edit/")
      ) {
        this.setState({
          isFullPageLayout: true,
        });
        document.querySelector(".az-content-wrapper").classList.add("p-0");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document.querySelector(".az-content-wrapper").classList.remove("p-0");
      }
    }
  }
}

export default withRouter(App);
