import React from "react";
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";

import Loading from "../ui-elements/Loading";
import FilterScript from "../ui-elements/FilterScript";

import { checkTokenLogout } from "../auth";

import {
  TextInputField,
  Badge,
  Button,
  ChartIcon,
  DuplicateIcon,
  EditIcon,
  Heading,
  IconButton,
  majorScale,
  Menu,
  Pane,
  PauseIcon,
  Tab,
  Table,
  TabNavigation,
  Text,
  TrashIcon,
  Popover,
  Position,
  PropertiesIcon,
  AddIcon,
  Dialog,
  SearchInput,
  toaster,
  EmptyState,
  SearchIcon,
  ArrowRightIcon,
  FilterIcon,
  NewGridItemIcon,
  Tablist,
  TextareaField,
} from "evergreen-ui";
import { ConvertTime } from "../api/utilities";
import PagingCustom from "../ui-elements/PagingCustom";

export function ProductGroups({ loadChild, setShowDialog }) {
  const history = useHistory("");
  const token = localStorage.getItem("token");
  const [isReload, setIsReload] = useState(false);
  const [progroups, setProgroups] = useState();
  const [isShown, setIsShown] = React.useState(false);
  const [progroupsInfo, setProgroupsInfo] = useState(null);
  const [progroupsId, setProgroupsId] = useState("");

  // -phan trang
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //

  // filter
  const [dataFilter, setDataFilter] = useState();
  const optionStatus = [
    { label: "Hoạt động", value: "Hoạt động" },
    { label: "Tạm dừng", value: "Tạm dừng" },
  ];
  // ===========

  const [selectedOpPGroup, setSelectedOpPGroup] = useState();

  const handleChangePGroup = (options) => {
    setSelectedOpPGroup(options);
  };

  const [showModalEditPGroup, setshowModalEditPGroup] = useState(false);

  const handleEditPGroup = (id) => {
    var progrInfo = progroups.find((cus) => cus._id === id);
    setProgroupsInfo(progrInfo);
    setshowModalEditPGroup(true);
    setProgroupsId(id);
    formikEdit.values.name = progroupsInfo?.name ? progroupsInfo?.name : "";
  };

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: progroupsInfo?.id ? progroupsInfo?.id : "",
      parent_id: progroupsInfo?.parent_id ? progroupsInfo?.parent_id : "",
      name: progroupsInfo?.name ? progroupsInfo?.name : "",
      slug: progroupsInfo?.slug ? progroupsInfo?.slug : "",
      // status: progroupsInfo?.status ? progroupsInfo?.status : "",
      type: progroupsInfo?.type ? progroupsInfo?.type : "",
    },
    validationSchema: Yup.object({
      id: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập mã nhóm sản phẩm"),
      parent_id: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập nhóm cha"),
      name: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập tên nhóm sản phẩm"),
      slug: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập slug của tên"),
      // status: Yup.string()
      //   .required("Vui lòng chọn trạng thái nhóm sản phẩm"),
      type: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập loại nhóm sản phẩm"),
    }),
    onSubmit: (values) => {
      if (progroupsId) {
        const url = "http://v2.nextify.vn/product_groups/" + progroupsId;
        const method = "PUT";
        const headers = {
          Authorization: "Bearer " + token,
        };

        var bodyFormData = new FormData();
        bodyFormData.append("id", values.id.trim());
        bodyFormData.append("parent_id", values.parent_id.trim());
        bodyFormData.append("name", values.name.trim());
        bodyFormData.append("slug", values.slug.trim());
        bodyFormData.append("status", selectedOpPGroup.value);
        bodyFormData.append("type", values.type.trim());
        axios({
          method: method,
          url: url,
          headers: headers,
          data: bodyFormData,
        })
          .then((res) => {
            const j_response = res.data;
            var code = j_response.code;
            if (code == 200) {
              toaster.success("Cập nhật nhóm sản phẩm thành công");
              setshowModalEditPGroup();
              setIsReload(!isReload);
            } else {
              let msg = j_response.msg;
              toaster.warning(msg);
            }
          })
          .catch((error) => {
            var status = error.response?.status;
            if (status == 401) {
              checkTokenLogout(history);
            } else if (status == 422) {
              toaster.warning("Vui lòng điền đầy đủ thông tin");
            } else {
              toaster.danger("Có lỗi xảy ra");
            }
          });
      } else {
        toaster.danger("Cập nhật không thành công");
      }
    },
  });

  useEffect(() => {
    const url = `http://v2.nextify.vn/product_groups?page=${page}&page_size=${pageSize}`;
    const method = "GET";
    const headers = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: method,
      url: url,
      headers: headers,
    })
      .then((res) => {
        const j_response = res.data;
        const data = j_response.data;
        setProgroups(data);
        setTotalItem(j_response.total ? j_response.total : 0);
        if (data.length === 0 && page > 1) {
          setPage(page - 1);
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status == 401) {
          checkTokenLogout(history);
        } else {
          toaster.danger("Có lỗi xảy ra");
        }
      });
  }, [isReload, loadChild, page]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Bạn chắc chắn muốn xoá?",
      text: "Bạn sẽ không thể hoàn tác",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Đồng ý",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = "http://v2.nextify.vn/product_groups/" + id;
        const method = "DELETE";
        const headers = {
          Authorization: "Bearer " + token,
        };
        axios({
          method: method,
          url: url,
          headers: headers,
        })
          .then((res) => {
            const j_response = res.data;
            var code = j_response.code;
            if (code == 200) {
              toaster.success("Xóa thành công");
              setIsReload(!isReload);
            } else {
              var msg = j_response.msg;
              toaster.warning(msg);
            }
          })
          .catch((error) => {
            var status = error.response?.status;
            if (status == 401) {
              checkTokenLogout(history);
            } else {
              toaster.danger("Có lỗi xảy ra");
            }
          });
      }
    });
  };

  return (
    <>
      <Dialog
        isShown={showModalEditPGroup}
        title="Chỉnh sửa nhóm sản phẩm"
        onCloseComplete={() => setshowModalEditPGroup(false)}
        width={850}
        hasFooter={false}
      >
        <form action="#/" onSubmit={formikEdit.handleSubmit}>
          <Pane>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label" htmlFor="idEdit">
                  Mã nhóm sản phẩm (<span className="text-danger">*</span>)
                </label>
                <TextInputField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập mã nhóm sản phẩm"
                  type="text"
                  name="id"
                  id="idEdit"
                  onChange={formikEdit.handleChange}
                  onBlur={formikEdit.handleBlur}
                  value={formikEdit.values.id}
                  validationMessage={
                    formikEdit.touched.id && formikEdit.errors.id
                      ? formikEdit.errors.id
                      : false
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="parent_idEdit">
                  ID nhóm cha (<span className="text-danger">*</span>)
                </label>
                <TextInputField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập ID nhóm cha"
                  type="text"
                  name="parent_id"
                  id="parent_idEdit"
                  onChange={formikEdit.handleChange}
                  onBlur={formikEdit.handleBlur}
                  value={formikEdit.values.parent_id}
                  validationMessage={
                    formikEdit.touched.parent_id && formikEdit.errors.parent_id
                      ? formikEdit.errors.parent_id
                      : false
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="nameEdit">
                  Tên nhóm sản phẩm (<span className="text-danger">*</span>)
                </label>
                <TextInputField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập tên nhóm sản phẩm"
                  type="text"
                  name="name"
                  id="nameEdit"
                  onChange={formikEdit.handleChange}
                  onBlur={formikEdit.handleBlur}
                  value={formikEdit.values.name}
                  validationMessage={
                    formikEdit.touched.name && formikEdit.errors.name
                      ? formikEdit.errors.name
                      : false
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="slugEdit">
                  Slug của tên (<span className="text-danger">*</span>)
                </label>
                <TextInputField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập Slug của tên"
                  type="text"
                  name="slug"
                  id="slugEdit"
                  onChange={formikEdit.handleChange}
                  onBlur={formikEdit.handleBlur}
                  value={formikEdit.values.slug}
                  validationMessage={
                    formikEdit.touched.slug && formikEdit.errors.slug
                      ? formikEdit.errors.slug
                      : false
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="statusEdit">
                  Trạng thái (<span className="text-danger">*</span>)
                </label>
                <Pane marginTop={13} marginBottom={24}>
                  <Select
                    placeholder="Chọn trạng thái"
                    id="statusEdit"
                    name="status"
                    onChange={handleChangePGroup}
                    onBlur={formikEdit.handleBlur}
                    // defaultValue={selectedOpCurrency}
                    options={[
                      { value: "Hoạt động", label: "Hoạt động" },
                      {
                        value: "Tạm dừng",
                        label: "Tạm dừng",
                      },
                    ]}
                    menuPlacement="auto"
                    styles={{
                      menuPortal: (base) => {
                        const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                        return { ...rest, zIndex: 9999 };
                      },
                    }}
                    menuPortalTarget={document.body}
                    isSearchable
                  />
                </Pane>
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="typeEdit">
                  Loại nhóm sản phẩm (<span className="text-danger">*</span>)
                </label>
                <TextInputField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập loại nhóm sản phẩm"
                  type="text"
                  name="type"
                  id="typeEdit"
                  onChange={formikEdit.handleChange}
                  onBlur={formikEdit.handleBlur}
                  value={formikEdit.values.type}
                  validationMessage={
                    formikEdit.touched.type && formikEdit.errors.type
                      ? formikEdit.errors.type
                      : false
                  }
                />
              </div>
            </div>
          </Pane>
          <Pane
            display="flex"
            alignItems="right"
            justifyContent="right"
            marginTop={20}
          >
            <Button
              height={40}
              type="submit"
              appearance="primary"
              marginRight={10}
            >
              Cập nhật
            </Button>
            <Button
              height={40}
              type="button"
              variant="secondary"
              onClick={() => setshowModalEditPGroup(false)}
            >
              Đóng
            </Button>
          </Pane>
        </form>
      </Dialog>
      {progroups != undefined ? (
        progroups.length === 0 ? (
          <EmptyState
            background="dark"
            title=""
            orientation="vertical"
            icon={<SearchIcon color="#C1C4D6" />}
            iconBgColor="#EDEFF5"
            description="Bạn chưa có nhóm sản phẩm nào, bắt đầu thêm mới nhóm sản phẩm."
            primaryCta={
              <EmptyState.PrimaryButton
                appearance="primary"
                iconBefore={ArrowRightIcon}
                padding={24}
                onClick={() => setShowDialog(true)}
              >
                Thêm nhóm sản phẩm
              </EmptyState.PrimaryButton>
            }
          />
        ) : (
          <>
            <div className="row row-sm mg-b-20">
              <div className="col-2">
                <Dialog
                  isShown={isShown}
                  title="Lọc nhóm sản phẩm"
                  onCloseComplete={() => setIsShown(false)}
                  hasFooter={false}
                >
                  <FilterScript
                    options={optionStatus}
                    data={dataFilter}
                    setData={setDataFilter}
                  ></FilterScript>
                </Dialog>

                <IconButton
                  icon={FilterIcon}
                  height={40}
                  width="100%"
                  onClick={() => setIsShown(true)}
                  color="green"
                ></IconButton>
              </div>
              <div className="col-10">
                <SearchInput
                  width="100%"
                  height={40}
                  placeholder="Tìm kiếm nhóm sản phẩm..."
                />
              </div>
            </div>
            <div className="table-responsive">
              <div className="card">
                <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Mã nhóm</th>
                      <th>ID nhóm cha</th>
                      <th>Tên</th>
                      <th>Slug của tên</th>
                      <th>Trạng thái</th>
                      <th>Loại</th>
                      <th>Cập nhật</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {progroups.map((progroup) => (
                      <tr>
                        <td
                          className="my-2"
                          onClick={() =>
                            history.push(
                              `/ecommerce/product_groups/${progroup?._id}`
                            )
                          }
                        >
                          {progroup.id}
                        </td>

                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/product_groups/${progroup?._id}`
                            )
                          }
                        >
                          {progroup.parent_id}
                        </td>

                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/product_groups/${progroup?._id}`
                            )
                          }
                        >
                          {progroup.name}
                        </td>
                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/product_groups/${progroup?._id}`
                            )
                          }
                        >
                          {progroup.slug}
                        </td>
                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/product_groups/${progroup?._id}`
                            )
                          }
                        >
                          {progroup.status === "Hoạt động" ? (
                            <Badge color="green">{progroup.status}</Badge>
                          ) : progroup.status === "Tạm dừng" ? (
                            <Badge color="red">{progroup.status}</Badge>
                          ) : (
                            <Badge color="yellow">{progroup.status}</Badge>
                          )}
                        </td>
                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/product_groups/${progroup?._id}`
                            )
                          }
                        >
                          {progroup.type}
                        </td>

                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/product_groups/${progroup?._id}`
                            )
                          }
                        >
                          {ConvertTime(
                            progroup.updated_at
                              ? progroup.updated_at
                              : progroup.created_at
                          )}
                        </td>
                        <td>
                          <Popover
                            position={Position.BOTTOM_LEFT}
                            content={
                              <Menu>
                                <Menu.Group>
                                  {/* <Link
                                to={`/ecommerce/product_groups/${progroup?._id}`}
                              >
                                <Menu.Item icon={NewGridItemIcon}>
                                  Sản phẩm
                                </Menu.Item>
                              </Link> */}

                                  <a href="#" className="text-dark">
                                    <Menu.Item
                                      icon={EditIcon}
                                      onClick={() =>
                                        handleEditPGroup(progroup?._id)
                                      }
                                    >
                                      Chỉnh sửa
                                    </Menu.Item>
                                  </a>
                                </Menu.Group>
                                <Menu.Divider />
                                <Menu.Group>
                                  <Menu.Item
                                    icon={TrashIcon}
                                    intent="danger"
                                    onClick={() => handleDelete(progroup?._id)}
                                  >
                                    Xóa
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <IconButton
                              marginRight={16}
                              icon={PropertiesIcon}
                            ></IconButton>
                          </Popover>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <PagingCustom
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  total={totalItem}
                ></PagingCustom>
              </div>
            </div>
          </>
        )
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}

export default ProductGroups;
