import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import axios from "axios";
import { checkTokenLogout } from "../auth";

import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Heading,
  Pill,
  Tab,
  TabNavigation,
  Pane,
  majorScale,
  Table,
  Text,
  TrashIcon,
  Icon,
  Button,
  IconButton,
  AddIcon,
  Card,
  EmptyState,
  SearchIcon,
  toaster,
  TextInputField,
  Dialog,
} from "evergreen-ui";
import PagingCustom from "../ui-elements/PagingCustom";
import Loading from "../ui-elements/Loading";

import { ConvertTime } from "../api/utilities";

export function ViewContactType() {
  const handleTabheader = (id) => {
    switch (id) {
      case 0:
        history.push("/contacts");
        break;
      case 1:
        history.push("/contacts/companies");
        break;
      case 2:
        history.push("/contacts/groups");
        break;
      case 3:
        history.push("/contacts/custom-fields");
        break;
      case 4:
        history.push("/contacts/contact-types");
        break;
      case 5:
        history.push("/contacts/ranks");
        break;
      case 6:
        history.push("/contacts/tags");
        break;
    }
  };
  const [TypeContact, setTypeContact] = useState();
  const [isReload, setIsReload] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const history = useHistory("");
  const token = localStorage.getItem("token");

  // phan trang group
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //

  // delete
  const handleDelete = (id) => {
    if (id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xoá?",
        text: "Bạn sẽ không thể hoàn tác",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy",
      }).then((result) => {
        if (result.isConfirmed) {
          const url = "http://v2.nextify.vn/contact_types/" + id;
          const method = "DELETE";
          const headers = {
            Authorization: "Bearer " + token,
          };
          axios({
            method: method,
            url: url,
            headers: headers,
          })
            .then((res) => {
              const j_response = res.data;
              var code = j_response.code;
              if (code == 200) {
                toaster.success("Xóa thành công");
                setIsReload(!isReload);
              } else {
                var msg = j_response.msg;
                toaster.warning(msg);
              }
            })
            .catch((error) => {
              var status = error.response?.status;
              if (status == 401) {
                checkTokenLogout(history);
              } else {
                toaster.danger("Có lỗi xảy ra");
              }
            });
        }
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập tên loại liên hệ"),
      description: Yup.string()
        .trim()
        .max(200, "Vui lòng không nhập quá 200 ký tự"),
    }),
    onSubmit: (values) => {
      const url = "http://v2.nextify.vn/contact_types";
      const method = "POST";
      const headers = {
        Authorization: "Bearer " + token,
      };
      var bodyFormData = new FormData();
      bodyFormData.append("name", values.name.trim());
      bodyFormData.append("description", values.description.trim());
      axios({
        method: method,
        url: url,
        headers: headers,
        data: bodyFormData,
      })
        .then((res) => {
          const j_response = res.data;
          var code = j_response.code;
          if (code == 200) {
            toaster.success("Thêm loại liên hệ thành công");
            formik.values.name = "";
            formik.values.description = "";
            setShowForm(!showForm);
            setIsReload(!isReload);
          } else {
            toaster.danger("Thêm loại liên hệ thất bại");
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 401) {
            checkTokenLogout(history);
          } else if (status == 422) {
            toaster.warning("Vui lòng điền đầy đủ thông tin");
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    },
  });

  useEffect(() => {
    const url = `http://v2.nextify.vn/contact_types?page=${page}&page_size=${pageSize}`;
    const method = "GET";
    const headers = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: method,
      url: url,
      headers: headers,
    })
      .then((res) => {
        const j_response = res.data;
        const typeContact = j_response.data;
        setTotal(j_response.total ? j_response.total : 0);
        setTypeContact(typeContact);

        // check phan trang
        if (typeContact.length === 0 && page > 1) {
          setPage(page - 1);
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status == 401) {
          checkTokenLogout(history);
        } else {
          toaster.danger("Có lỗi xảy ra");
        }
      });
  }, [isReload, page]);

  return (
    <div>
      <Dialog>
        <form action="#/" onSubmit={formik.handleSubmit} id="form_add">
          <div className="form-row">
            <div className="col-md-6">
              <label className="form-label" htmlFor="tp_name">
                Tên loại liên hệ (<span className="text-danger">*</span>)
              </label>
              <TextInputField
                inputHeight={40}
                label=""
                placeholder="Nhập tên loại liên hệ"
                type="text"
                id="tp_name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                validationMessage={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : false
                }
              />
            </div>
            <div className="col-md-6">
              <label className="form-label" htmlFor="tp_description">
                Mô tả
              </label>
              <TextInputField
                inputHeight={40}
                label=""
                placeholder="Nhập mô tả"
                type="text"
                id="tp_description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                validationMessage={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : false
                }
              />
            </div>
          </div>
          <Pane display="flex" alignItems="right" justifyContent="right">
            <Button type="submit" iconBefore={AddIcon} appearance="primary">
              Thêm loại liên hệ
            </Button>
          </Pane>
        </form>
      </Dialog>
      <div className="container">
        <div className="az-content-body">
          <div className="row">
            <div className="col-8">
              <div className="az-dashboard-one-title">
                <div>
                  <Heading>
                    Loại liên hệ
                    <Pill display="inline-flex" marginLeft={8} color="blue">
                      0
                    </Pill>
                  </Heading>
                  <p className="az-dashboard-text">
                    Tạo và quản lý các loại liên hệ.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="float-right">
                <div className="btn-icon-list"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TabNavigation>
                {[
                  "Liên hệ",
                  "Tổ chức",
                  "Phân khúc",
                  "Dữ liệu động",
                  "Loại liên hệ",
                  "Hạng thành viên",
                  "Thẻ",
                ].map((tab, index) => (
                  <Tab
                    key={tab}
                    is="a"
                    href="#"
                    id={tab}
                    isSelected={index === 4}
                    onClick={() => handleTabheader(index)}
                  >
                    {tab}
                  </Tab>
                ))}
              </TabNavigation>
              <hr />

              <div className="table-responsive datatable-custom">
                <Pane display="flex" alignItems="right" justifyContent="right">
                  <Button
                    marginBottom={16}
                    color="blue"
                    appearance="minimal"
                    padding={16}
                    iconBefore={AddIcon}
                    onClick={() => setShowForm(!showForm)}
                  >
                    Thêm loại liên hệ
                  </Button>
                </Pane>

                <Pane
                  margint={10}
                  padding={15}
                  hidden={showForm}
                  background="tint2"
                >
                  <form
                    action="#/"
                    onSubmit={formik.handleSubmit}
                    id="form_add"
                  >
                    <div className="form-row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="tp_name">
                          Tên loại liên hệ (
                          <span className="text-danger">*</span>)
                        </label>
                        <TextInputField
                          inputHeight={40}
                          label=""
                          placeholder="Nhập tên loại liên hệ"
                          type="text"
                          id="tp_name"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                          validationMessage={
                            formik.touched.name && formik.errors.name
                              ? formik.errors.name
                              : false
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="tp_description">
                          Mô tả
                        </label>
                        <TextInputField
                          inputHeight={40}
                          label=""
                          placeholder="Nhập mô tả"
                          type="text"
                          id="tp_description"
                          name="description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                          validationMessage={
                            formik.touched.description &&
                            formik.errors.description
                              ? formik.errors.description
                              : false
                          }
                        />
                      </div>
                    </div>
                    <Pane
                      display="flex"
                      alignItems="right"
                      justifyContent="right"
                    >
                      <Button
                        type="submit"
                        iconBefore={AddIcon}
                        appearance="primary"
                      >
                        Thêm loại liên hệ
                      </Button>
                    </Pane>
                  </form>
                </Pane>
                {TypeContact !== undefined ? (
                  <>
                    {TypeContact.length > 0 ? (
                      <div className="card">
                        <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                          <thead>
                            <tr>
                              <th className="align-middle">Tên loại liên hệ</th>
                              <th className="align-middle">Mô tả </th>
                              <th className="align-middle">Tạo lúc</th>
                              <th className="align-middle">Hành động</th>
                            </tr>
                          </thead>
                          <tbody>
                            {TypeContact.map((typect) => (
                              <tr>
                                <td className="align-middle">{typect.name}</td>
                                <td className="align-middle">
                                  {typect.description}
                                </td>
                                <td className="align-middle">
                                  {ConvertTime(typect.created_at)}
                                </td>
                                <td className="align-middle">
                                  {typect.default ? (
                                    <Pane></Pane>
                                  ) : (
                                    <Pane display="flex" alignItems="center">
                                      <IconButton
                                        icon={TrashIcon}
                                        intent="danger"
                                        title="Xóa"
                                        marginRight={majorScale(2)}
                                        onClick={() => handleDelete(typect._id)}
                                      />
                                    </Pane>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <PagingCustom
                          page={page}
                          setPage={setPage}
                          pageSize={pageSize}
                          total={total}
                        ></PagingCustom>
                      </div>
                    ) : (
                      <EmptyState
                        background="dark"
                        title=""
                        orientation="vertical"
                        icon={<SearchIcon color="#C1C4D6" />}
                        iconBgColor="#EDEFF5"
                        description="Chưa có loại liên hệ nào."
                      />
                    )}
                  </>
                ) : (
                  <Loading></Loading>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ViewContactType;
