import React from "react";
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";

import { checkTokenLogout } from "../auth";

import Loading from "../ui-elements/Loading";
import FilterScript from "../ui-elements/FilterScript";

import {
  TextInputField,
  Badge,
  Button,
  ChartIcon,
  DuplicateIcon,
  EditIcon,
  Heading,
  IconButton,
  majorScale,
  Menu,
  Pane,
  PauseIcon,
  Tab,
  Table,
  TabNavigation,
  Text,
  TrashIcon,
  Popover,
  Position,
  PropertiesIcon,
  AddIcon,
  Dialog,
  SearchInput,
  toaster,
  EmptyState,
  SearchIcon,
  ArrowRightIcon,
  FilterIcon,
  NewGridItemIcon,
  Tablist,
  TextareaField,
} from "evergreen-ui";
import { ConvertTime } from "../api/utilities";
import PagingCustom from "../ui-elements/PagingCustom";

export function ProductCategory({ loadChild, setShowDialog }) {
  const history = useHistory("");
  const token = localStorage.getItem("token");
  const [isReload, setIsReload] = useState(false);
  const [categories, setCategories] = useState();
  const [isShown, setIsShown] = React.useState(false);
  const [categoryInfo, setCategoryInfo] = useState(null);
  const [categoryId, setCategoryId] = useState("");

  // -phan trang
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //

  // filter
  const [dataFilter, setDataFilter] = useState();
  const optionStatus = [
    { label: "Hoạt động", value: "Hoạt động" },
    { label: "Tạm dừng", value: "Tạm dừng" },
  ];
  // ===========

  const [showModalEditCategory, setshowModalEditCategory] = useState(false);

  const handleEditCategory = (id) => {
    var cateInfo = categories.find((cus) => cus._id === id);
    setCategoryInfo(cateInfo);
    setshowModalEditCategory(true);
    setCategoryId(id);
    formikEdit.values.name = categoryInfo?.name ? categoryInfo?.name : "";
    formikEdit.values.description = categoryInfo?.description
      ? categoryInfo?.description
      : "";
  };

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: categoryInfo?.name ? categoryInfo?.name : "",
      description: categoryInfo?.description ? categoryInfo?.description : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập tên loại sản phẩm"),
      description: Yup.string(),
    }),
    onSubmit: (values) => {
      if (categoryId) {
        const url = "http://v2.nextify.vn/categories/" + categoryId;
        const method = "PUT";
        const headers = {
          Authorization: "Bearer " + token,
        };

        var bodyFormData = new FormData();
        bodyFormData.append("name", values.name.trim());
        bodyFormData.append("description", values.description.trim());

        axios({
          method: method,
          url: url,
          headers: headers,
          data: bodyFormData,
        })
          .then((res) => {
            const j_response = res.data;
            var code = j_response.code;
            if (code == 200) {
              toaster.success("Cập nhật loại sản phẩm thành công");
              setshowModalEditCategory();
              setIsReload(!isReload);
            } else {
              let msg = j_response.msg;
              toaster.warning(msg);
            }
          })
          .catch((error) => {
            var status = error.response?.status;
            if (status == 401) {
              checkTokenLogout(history);
            } else if (status == 422) {
              toaster.warning("Vui lòng điền đầy đủ thông tin");
            } else {
              toaster.danger("Có lỗi xảy ra");
            }
          });
      } else {
        toaster.danger("Cập nhật không thành công");
      }
    },
  });

  useEffect(() => {
    const url = `http://v2.nextify.vn/categories?page=${page}&page_size=${pageSize}`;
    const method = "GET";
    const headers = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: method,
      url: url,
      headers: headers,
    })
      .then((res) => {
        const j_response = res.data;
        const data = j_response.data;
        setCategories(data);
        setTotalItem(j_response.total ? j_response.total : 0);
        if (data.length === 0 && page > 1) {
          setPage(page - 1);
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status == 401) {
          checkTokenLogout(history);
        } else {
          toaster.danger("Có lỗi xảy ra");
        }
      });
  }, [isReload, loadChild, page]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Bạn chắc chắn muốn xoá?",
      text: "Bạn sẽ không thể hoàn tác",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Đồng ý",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = "http://v2.nextify.vn/categories/" + id;
        const method = "DELETE";
        const headers = {
          Authorization: "Bearer " + token,
        };
        axios({
          method: method,
          url: url,
          headers: headers,
        })
          .then((res) => {
            const j_response = res.data;
            var code = j_response.code;
            if (code == 200) {
              toaster.success("Xóa thành công");
              setIsReload(!isReload);
            } else {
              var msg = j_response.msg;
              toaster.warning(msg);
            }
          })
          .catch((error) => {
            var status = error.response?.status;
            if (status == 401) {
              checkTokenLogout(history);
            } else {
              toaster.danger("Có lỗi xảy ra");
            }
          });
      }
    });
  };

  return (
    <>
      <Dialog
        isShown={showModalEditCategory}
        title="Chỉnh sửa loại sản phẩm"
        onCloseComplete={() => setshowModalEditCategory(false)}
        width={850}
        hasFooter={false}
      >
        <form action="#/" onSubmit={formikEdit.handleSubmit}>
          <Pane>
            <label className="form-label" htmlFor="nameEdit">
              Tên loại sản phẩm
            </label>
            <TextInputField
              label=""
              inputHeight={40}
              placeholder="Nhập tên loại sản phẩm"
              type="text"
              name="name"
              id="nameEdit"
              onChange={formikEdit.handleChange}
              // onBlur={formikEdit.handleBlur}
              value={formikEdit.values.name}
              validationMessage={
                formikEdit.touched.name && formikEdit.errors.name
                  ? formikEdit.errors.name
                  : false
              }
            />

            <label className="form-label" htmlFor="descriptionEdit">
              Mô tả
            </label>
            <TextareaField
              label=""
              inputHeight={40}
              placeholder="Nhập mô tả"
              type="text"
              name="description"
              id="descriptionEdit"
              onChange={formikEdit.handleChange}
              // onBlur={formikEdit.handleBlur}
              value={formikEdit.values.description}
            />
          </Pane>
          <Pane
            display="flex"
            alignItems="right"
            justifyContent="right"
            marginTop={20}
          >
            <Button
              height={40}
              type="submit"
              appearance="primary"
              marginRight={10}
            >
              Cập nhật
            </Button>
            <Button
              height={40}
              type="button"
              variant="secondary"
              onClick={() => setshowModalEditCategory(false)}
            >
              Đóng
            </Button>
          </Pane>
        </form>
      </Dialog>

      {categories != undefined ? (
        categories.length === 0 ? (
          <EmptyState
            background="dark"
            title=""
            orientation="vertical"
            icon={<SearchIcon color="#C1C4D6" />}
            iconBgColor="#EDEFF5"
            description="Bạn chưa có loại sản phẩm nào, bắt đầu thêm mới loại sản phẩm."
            primaryCta={
              <EmptyState.PrimaryButton
                appearance="primary"
                iconBefore={ArrowRightIcon}
                padding={24}
                onClick={() => setShowDialog(true)}
              >
                Thêm loại sản phẩm
              </EmptyState.PrimaryButton>
            }
          />
        ) : (
          <>
            {/* <div className="row row-sm mg-b-20">
              <div className="col-2">
                <Dialog
                  isShown={isShown}
                  title="Lọc loại sản phẩm"
                  onCloseComplete={() => setIsShown(false)}
                  hasFooter={false}
                >
                  <FilterScript
                    options={optionStatus}
                    data={dataFilter}
                    setData={setDataFilter}
                  ></FilterScript>
                </Dialog>

                <IconButton
                  icon={FilterIcon}
                  width="100%"
                  height={40}
                  onClick={() => setIsShown(true)}
                  color="green"
                ></IconButton>
              </div>
              <div className="col-10">
                <SearchInput
                  width="100%"
                  height={40}
                  placeholder="Tìm kiếm loại sản phẩm..."
                />
              </div>
            </div> */}
            <div className="table-responsive">
              <div className="card">
                <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Tên</th>
                      <th>Sản phẩm</th>
                      <th>Cập nhật</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((categorie) => (
                      <tr>
                        <td
                          className="my-2"
                          onClick={() =>
                            history.push(
                              `/ecommerce/categories/${categorie?._id}`
                            )
                          }
                        >
                          {categorie.name}
                        </td>

                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/categories/${categorie?._id}`
                            )
                          }
                        >
                          {categorie.total_products}
                        </td>

                        <td
                          onClick={() =>
                            history.push(
                              `/ecommerce/categories/${categorie?._id}`
                            )
                          }
                        >
                          {ConvertTime(
                            categorie.updated_at
                              ? categorie.updated_at
                              : categorie.created_at
                          )}
                        </td>
                        <td>
                          <Popover
                            position={Position.BOTTOM_LEFT}
                            content={
                              <Menu>
                                <Menu.Group>
                                  <Link
                                    to={`/ecommerce/categories/${categorie?._id}`}
                                  >
                                    <Menu.Item icon={NewGridItemIcon}>
                                      Sản phẩm
                                    </Menu.Item>
                                  </Link>

                                  <a href="#" className="text-dark">
                                    <Menu.Item
                                      icon={EditIcon}
                                      onClick={() =>
                                        handleEditCategory(categorie?._id)
                                      }
                                    >
                                      Chỉnh sửa
                                    </Menu.Item>
                                  </a>
                                </Menu.Group>
                                <Menu.Divider />
                                <Menu.Group>
                                  <Menu.Item
                                    icon={TrashIcon}
                                    intent="danger"
                                    onClick={() => handleDelete(categorie?._id)}
                                  >
                                    Xóa
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <IconButton
                              marginRight={16}
                              icon={PropertiesIcon}
                            ></IconButton>
                          </Popover>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <PagingCustom
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  total={totalItem}
                ></PagingCustom>
              </div>
            </div>
          </>
        )
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}

export default ProductCategory;
