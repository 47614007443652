export const ConvertTime = (timestamp) => {
  const dt = new Date(timestamp);
  const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
  const dformat = `${padL(dt.getDate())}/${padL(
    dt.getMonth() + 1
  )}/${dt.getFullYear()} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(
    dt.getSeconds()
  )}`;

  return dformat;
};

export const ConvertMoney = (value) => {
  value = Number.parseInt(value);
  var result = value.toLocaleString("it-IT", {
    style: "currency",
    currency: "VND",
  });
  result = result
    .replace("VND", "")
    .replace(".", "@")
    .replace(",", ".")
    .replace("@", ",")
    .trim();
  return result;
};

export const dataDefaultDraftHtml = `
<div class="blockelem noselect block" style="left: 435px; top: 26px">
  <input
    type="hidden"
    name="blockelemtype"
    class="blockelemtype"
    value="{event}"
  />
  <input type="hidden" name="blockid" class="blockid" value="0" />
  <div class="grabme"><img src="/images/builder/grabme.svg" /></div>
  <input type='hidden' name='properid' class='properid' value='111'>
  <div class="blockin">
    <div class="blockico">
      <span></span>
      <p class="{icon}"></p>
    </div>
    <div class="blocktext">
      <p class="blocktitle">{name}</p>
      <p class="blockdesc">{description}</p>
    </div>
    </div>
    <div class="indicator invisible"></div>
</div>
`;

export const dataDefaultDraftBlockarr = [
  {
    childwidth: 318,
    parent: -1,
    id: 0,
    x: 594,
    y: 77,
    width: 318,
    height: 99,
  },
];

export const dataDefaultDraftBlocks = [
  {
    id: 0,
    parent: -1,
    data: [
      {
        name: "blockelemtype",
        value: "event_1",
      },
      {
        name: "blockid",
        value: "0",
      },
    ],
    attr: [
      {
        class: "blockelem noselect block",
      },
      {
        style: "left: 435px; top: 26px;",
      },
    ],
  },
];

export const urlFileStorage = "http://125.212.225.71:8089/";
export const urlApiCdp = "http://v2.nextify.vn";
