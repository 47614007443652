import React from "react";
import Select from "react-select";

import {
  Pane,
  FilterIcon,
  Button,
  Heading,
  TextInputField,
} from "evergreen-ui";

const optionStatus = [
  { label: "Đã tiếp nhận", value: "Chưa xác nhận" },
  { label: "Đã xác nhận", value: "Đã xác nhận" },
  { label: "Đang đóng gói", value: "Đang đóng gói" },
  { label: "Giao vận chuyển", value: "Đã xác nhận" },
  { label: "Hoàn", value: "Đã xác nhận" },
  { label: "Hủy", value: "Đã xác nhận" },
  { label: "Chưa hoàn thành", value: "Đã xác nhận" },
  { label: "Hoàn thành", value: "Đã xác nhận" }
];


const optionsPayment = [
  { label: "Chưa thanh toán", value: "Chưa xác nhận" },
  { label: "Đã thanh toán", value: "Đã xác nhận" },
];


const optionsDelivery = [
  { label: "Chưa xác nhận", value: "Chưa xác nhận" },
  { label: "Đã xác nhận", value: "Đã xác nhận" },
];

const FilterScript = ({ setData }) => {
  return (
    <Pane>
      <Pane>
      {/* "Đã tiếp nhận", "Đã xác nhận", "Đang đóng gói", "Giao vận chuyển", "Đã giao hàng", "Hủy", "Hoàn", "Tất cả" */}
        <Heading size={400} marginBottom={15}>
          Trạng thái đơn hàng
        </Heading>
        <Select
          placeholder="Lựa chọn"
          defaultValue={optionStatus[0]}
          options={optionStatus}
          menuPlacement="auto"
          styles={{
            menuPortal: (base) => {
              const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
              return { ...rest, zIndex: 9999 };
            },
          }}
          menuPortalTarget={document.body}
          isSearchable
        ></Select>
         <Heading size={400} marginTop={15} marginBottom={15}>
          Trạng thái thanh toán 
        </Heading>
        <Select
          placeholder="Lựa chọn"
          defaultValue={optionsPayment[0]}
          options={optionsPayment}
          menuPlacement="auto"
          styles={{
            menuPortal: (base) => {
              const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
              return { ...rest, zIndex: 9999 };
            },
          }}
          menuPortalTarget={document.body}
          isSearchable
        ></Select>
         <Heading size={400} marginTop={15} marginBottom={15}>
          Trạng thái vận chuyển
        </Heading>
        <Select
          placeholder="Lựa chọn"
          defaultValue={optionsDelivery[0]}
          options={optionsDelivery}
          menuPlacement="auto"
          styles={{
            menuPortal: (base) => {
              const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
              return { ...rest, zIndex: 9999 };
            },
          }}
          menuPortalTarget={document.body}
          isSearchable
        ></Select>
        <Pane display="flex" marginTop={24}>
          <Pane flex="1" marginRight={15}>
            <TextInputField label="Ngày bắt đầu" type="date" inputHeight={40}></TextInputField>
          </Pane>
          <Pane flex="1">
            <TextInputField label="Ngày kết thúc" type="date" inputHeight={40}></TextInputField>
          </Pane>
        </Pane>
      </Pane>

      <Pane
        display="flex"
        alignItems="right"
        justifyContent="right"
        marginTop={24}
        marginBottom={24}
      >
        <Button type="button" appearance="primary" iconBefore={FilterIcon}>
          Lọc
        </Button>
      </Pane>
    </Pane>
  );
};

export default FilterScript;
