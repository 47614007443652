import React from "react";
import { Spinner, Pane } from "evergreen-ui";
import { Spin } from "antd";
import "antd/dist/antd.css";

const Loading = ({ height = "50vh" }) => {
  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={height}
      width="100%"
    >
      <Spin tip="" size="large"></Spin>
    </Pane>
  );
};

export default Loading;
