import React from "react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

import Loading from "../ui-elements/Loading";
import FilterScript from "../ui-elements/FilterScript";
import PagingCustom from "../ui-elements/PagingCustom";

import { checkTokenLogout } from "../auth";

import {
  ChangesIcon,
  ExchangeIcon,
  TextareaField,
  TextInputField,
  Badge,
  Button,
  ChartIcon,
  DuplicateIcon,
  EditIcon,
  Heading,
  IconButton,
  Menu,
  Pane,
  Table,
  Text,
  TrashIcon,
  Popover,
  Position,
  PropertiesIcon,
  AddIcon,
  Dialog,
  SearchInput,
  toaster,
  EmptyState,
  SearchIcon,
  ArrowRightIcon,
  FilterIcon,
  NewGridItemIcon,
  Tablist,
} from "evergreen-ui";
import { ConvertTime } from "../api/utilities";
export function Stores({ loadChild, setShowDialog }) {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [isReload, setIsReload] = useState(false);
  const [stores, setStores] = useState();
  const [isShown, setIsShown] = React.useState(false);

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [storesInfo, setStoresInfo] = useState(null);
  const [storeId, setStoreId] = useState("");

  // filter
  const [dataFilter, setDataFilter] = useState();
  const optionStatus = [
    { label: "Hoạt động", value: "Hoạt động" },
    { label: "Tạm dừng", value: "Tạm dừng" },
  ];
  // ===========

  // -phan trang
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //

  const [selectedOpStore_type, setSelectedOpStore_type] = useState();

  const handleChangeStore_type = (options) => {
    setSelectedOpStore_type(options);
  };
  const [selectedOpStatus, setSelectedOpStatus] = useState();

  const handleChangeStatus = (options) => {
    setSelectedOpStatus(options);
  };
  const [selectedOpCurrency, setSelectedOpCurrency] = useState();

  const handleChangeCurrency = (options) => {
    setSelectedOpCurrency(options);
  };

  const handleEditStore = (id) => {
    var stoInfo = stores.find((cus) => cus._id === id);
    setStoresInfo(stoInfo);
    setShowModalEdit(true);
    formikEdit.values.name = storesInfo?.name ? storesInfo?.name : "";
    formikEdit.values.store_type = storesInfo?.store_type
      ? storesInfo?.store_type
      : "";
    formikEdit.values.currency = storesInfo?.currency
      ? storesInfo?.currency
      : "";
    formikEdit.values.status = storesInfo?.status ? storesInfo?.status : "";
  };

  useEffect(() => {
    const url = `http://v2.nextify.vn/marketing/stores?page=${page}&page_size=${pageSize}`;
    const method = "GET";
    const headers = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: method,
      url: url,
      headers: headers,
    })
      .then((res) => {
        const j_response = res.data;
        const data = j_response.data;
        setStores(data);

        setTotalItem(j_response.total ? j_response.total : 0);
        if (data.length === 0 && page > 1) {
          setPage(page - 1);
        }
      })
      .catch((error) => {
        var status = error.response?.status;
        if (status == 401) {
          checkTokenLogout(history);
        } else {
          toaster.danger("Có lỗi xảy ra");
        }
      });
  }, [isReload, loadChild, page]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Bạn chắc chắn muốn xoá?",
      text: "Bạn sẽ không thể hoàn tác",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Đồng ý",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = "http://v2.nextify.vn/marketing/stores/" + id;
        const method = "DELETE";
        const headers = {
          Authorization: "Bearer " + token,
        };
        axios({
          method: method,
          url: url,
          headers: headers,
        })
          .then((res) => {
            const j_response = res.data;
            var code = j_response.code;
            if (code == 200) {
              toaster.success("Xóa thành công");
              setIsReload(!isReload);
            } else {
              var msg = j_response.msg;
              toaster.warning(msg);
            }
          })
          .catch((error) => {
            var status = error.response?.status;
            if (status == 401) {
              checkTokenLogout(history);
            } else {
              toaster.danger("Có lỗi xảy ra");
            }
          });
      }
    });
  };

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: storesInfo?.name ? storesInfo?.name : "",
      store_type: storesInfo?.store_type ? storesInfo?.store_type : "",
      currency: storesInfo?.currency ? storesInfo?.currency : "",
      status: storesInfo?.status ? storesInfo?.status : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .max(50, "Vui lòng không nhập quá 50 ký tự")
        .required("Vui lòng nhập tên gian hàng"),
      store_type: Yup.string().required("Vui lòng chọn loại gian hàng"),
      currency: Yup.string().required("Vui lòng chọn tiền tệ"),
      status: Yup.string().required("Vui lòng chọn trạng thái"),
    }),
    onSubmit: (values) => {
      const url = "http://v2.nextify.vn/marketing/stores/" + storeId;
      const method = "PUT";
      const headers = {
        Authorization: "Bearer " + token,
      };

      var bodyFormData = new FormData();
      bodyFormData.append("name", values.name.trim());
      bodyFormData.append("store_type", selectedOpStore_type.value);
      bodyFormData.append("currency", selectedOpCurrency.value);
      bodyFormData.append("status", selectedOpStatus.value);

      axios({
        method: method,
        url: url,
        headers: headers,
        data: bodyFormData,
      })
        .then((res) => {
          const j_response = res.data;
          var code = j_response.code;
          if (code == 200) {
            toaster.success("Cập nhật gian hàng thành công");
            setShowModalEdit(false);
            setIsReload(!isReload);
          } else {
            let msg = j_response.msg;
            toaster.danger(msg, {
              position: "bottom-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 401) {
            checkTokenLogout(history);
          } else if (status == 422) {
            toaster.warning("Vui lòng điền đầy đủ thông tin");
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    },
  });

  useEffect(() => {
    setStoreId(storesInfo?._id);
    if (storesInfo?.status) {
      setSelectedOpStatus({
        label: storesInfo?.status,
        value: storesInfo?.status,
      });
    } else {
      setSelectedOpStatus({
        value: "Tạm dừng",
        label: "Tạm dừng",
      });
    }
    if (storesInfo?.store_type) {
      setSelectedOpStore_type({
        label: storesInfo?.store_type,
        value: storesInfo?.store_type,
      });
    } else {
      setSelectedOpStore_type({});
    }
    if (storesInfo?.currency) {
      setSelectedOpCurrency({
        label: storesInfo?.currency,
        value: storesInfo?.currency,
      });
    } else {
      setSelectedOpCurrency({});
    }
  }, [storesInfo, stores]);

  const handleStatus = (id) => {
    var stoInfo = stores.find((cus) => cus._id === id);
    if (stoInfo) {
      const url = "http://v2.nextify.vn/marketing/stores/" + id;
      const method = "PUT";
      const headers = {
        Authorization: "Bearer " + token,
      };

      var status = "Tạm dừng";
      if (stoInfo.status !== "Hoạt động") {
        status = "Hoạt động";
      }

      var bodyFormData = new FormData();
      bodyFormData.append("status", status);

      axios({
        method: method,
        url: url,
        headers: headers,
        data: bodyFormData,
      })
        .then((res) => {
          const j_response = res.data;
          var code = j_response.code;
          if (code == 200) {
            toaster.success("Cập nhật trạng thái thành công");
            setIsReload(!isReload);
          } else {
            let msg = j_response.msg;
            toaster.danger(msg, {
              position: "bottom-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 401) {
            checkTokenLogout(history);
          } else if (status == 422) {
            toaster.warning("Vui lòng điền đầy đủ thông tin");
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    } else {
      toaster.danger("Có lỗi xảy ra");
    }
  };
  return (
    <>
      <Dialog
        isShown={showModalEdit}
        title="Chỉnh sửa sản phẩm"
        onCloseComplete={() => setShowModalEdit(false)}
        width={850}
        hasFooter={false}
      >
        <form action="#/" onSubmit={formikEdit.handleSubmit} id="form_edit">
          <Pane>
            <div className="form-row">
              <div className="col-md-6">
                <label className="form-label" htmlFor="nameEdit">
                  Tên gian hàng (<span className="text-danger">*</span>)
                </label>
                <TextInputField
                  label=""
                  inputHeight={40}
                  placeholder="Nhập tên gian hàng"
                  type="text"
                  name="name"
                  id="nameEdit"
                  onChange={formikEdit.handleChange}
                  // onBlur={formikEdit.handleBlur}
                  value={formikEdit.values.name}
                  validationMessage={
                    formikEdit.touched.name && formikEdit.errors.name
                      ? formikEdit.errors.name
                      : false
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="store_typeEdit">
                  Loại gian hàng
                </label>
                <Pane marginTop={13}>
                  <Select
                    id="store_typeEdit"
                    name="store_type"
                    value={selectedOpStore_type}
                    // onBlur={formikEdit.handleBlur}
                    onChange={(option) => {
                      handleChangeStore_type(option);
                      formikEdit.handleChange("store_type")(option.value);
                    }}
                    options={[
                      { value: "Thủ công", label: "Thủ công" },
                      { value: "KiotViet", label: "KiotViet" },
                      { value: "Haravan", label: "Haravan" },
                      { value: "Nhanh.vn", label: "Nhanh.vn" },
                    ]}
                    menuPlacement="auto"
                    styles={{
                      menuPortal: (base) => {
                        const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                        return { ...rest, zIndex: 9999 };
                      },
                    }}
                    menuPortalTarget={document.body}
                    isSearchable
                  ></Select>
                </Pane>
                {formikEdit.touched.store_type &&
                formikEdit.errors.store_type ? (
                  <div className="valid_feedback">
                    <svg viewBox="0 0 16 16">
                      <path
                        d="M7.99-.01c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-3h-2v-7h2v7z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                    <p>{formikEdit.errors.store_type}</p>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="currencyEdit">
                  Đơn vị tiền
                </label>
                <Pane marginTop={13}>
                  <Select
                    id="currencyEdit"
                    name="currency"
                    value={selectedOpCurrency}
                    // onBlur={formikEdit.handleBlur}
                    onChange={(option) => {
                      handleChangeCurrency(option);
                      formikEdit.handleChange("currency")(option.value);
                    }}
                    options={[
                      { value: "VND", label: "VND" },
                      { value: "USD", label: "USD" },
                    ]}
                    menuPlacement="auto"
                    styles={{
                      menuPortal: (base) => {
                        const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                        return { ...rest, zIndex: 9999 };
                      },
                    }}
                    menuPortalTarget={document.body}
                    isSearchable
                  ></Select>
                </Pane>
                {formikEdit.touched.currency && formikEdit.errors.currency ? (
                  <div className="valid_feedback">
                    <svg viewBox="0 0 16 16">
                      <path
                        d="M7.99-.01c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-3h-2v-7h2v7z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                    <p>{formikEdit.errors.currency}</p>
                  </div>
                ) : null}
              </div>

              <div className="col-md-6">
                <label className="form-label" htmlFor="statusEdit">
                  Trạng thái
                </label>
                <Pane marginTop={13}>
                  <Select
                    id="statusEdit"
                    name="status"
                    value={selectedOpStatus}
                    // onBlur={formikEdit.handleBlur}
                    onChange={(option) => {
                      handleChangeStatus(option);
                      formikEdit.handleChange("status")(option.value);
                    }}
                    options={[
                      {
                        value: "Hoạt động",
                        label: "Hoạt động",
                      },
                      {
                        value: "Tạm dừng",
                        label: "Tạm dừng",
                      },
                    ]}
                    menuPlacement="auto"
                    styles={{
                      menuPortal: (base) => {
                        const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
                        return { ...rest, zIndex: 9999 };
                      },
                    }}
                    menuPortalTarget={document.body}
                    isSearchable
                  ></Select>
                </Pane>
                {formikEdit.touched.status && formikEdit.errors.status ? (
                  <div className="valid_feedback">
                    <svg viewBox="0 0 16 16">
                      <path
                        d="M7.99-.01c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-3h-2v-7h2v7z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                    <p>{formikEdit.errors.status}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </Pane>
          <Pane
            display="flex"
            alignItems="right"
            justifyContent="right"
            marginTop={20}
          >
            <Button type="submit" appearance="primary" marginRight={8}>
              Cập nhật
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setShowModalEdit(false)}
            >
              Đóng
            </Button>
          </Pane>
        </form>
      </Dialog>
      {stores !== undefined ? (
        stores.length === 0 ? (
          <EmptyState
            background="dark"
            title=""
            orientation="vertical"
            icon={<SearchIcon color="#C1C4D6" />}
            iconBgColor="#EDEFF5"
            description="Bạn chưa có gian hàng nào, bắt đầu thêm mới gian hàng."
            primaryCta={
              <EmptyState.PrimaryButton
                appearance="primary"
                iconBefore={ArrowRightIcon}
                padding={24}
                height={40}
                onClick={() => setShowDialog(true)}
              >
                Thêm gian hàng
              </EmptyState.PrimaryButton>
            }
          />
        ) : (
          <>
            {/* <div className="row row-sm mg-b-20">
              <div className="col-2">
                <Dialog
                  isShown={isShown}
                  title="Lọc gian hàng"
                  onCloseComplete={() => setIsShown(false)}
                  hasFooter={false}
                >
                  <FilterScript
                    options={optionStatus}
                    data={dataFilter}
                    setData={setDataFilter}
                  ></FilterScript>
                </Dialog>

                <IconButton
                  icon={FilterIcon}
                  height={40}
                  onClick={() => setIsShown(true)}
                  width="100%"
                  color="green"
                ></IconButton>
              </div>
              <div className="col-10">
                <SearchInput
                  width="100%"
                  height={40}
                  placeholder="Tìm kiếm gian hàng..."
                />
              </div>
            </div> */}
            <div className="table-responsive">
              <div className="card">
                <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                  <thead>
                    <tr>
                      <th className="align-middle">Tên</th>
                      <th className="align-middle">Loại</th>
                      <th className="align-middle">Tiền tệ</th>
                      <th className="align-middle">Sản phẩm</th>
                      <th className="align-middle">Trạng thái</th>
                      <th className="align-middle">Cập nhật</th>
                      <th className="align-middle"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {stores.map((store) => (
                      <tr>
                        <th
                          scope="row"
                          className="align-middle"
                          onClick={() =>
                            history.push(`/ecommerce/stores/${store?._id}`)
                          }
                        >
                          {store.name}
                        </th>
                        <td
                          className="align-middle"
                          onClick={() =>
                            history.push(`/ecommerce/stores/${store?._id}`)
                          }
                        >
                          {store.store_type}
                        </td>
                        <td
                          className="align-middle"
                          onClick={() =>
                            history.push(`/ecommerce/stores/${store?._id}`)
                          }
                        >
                          {store.currency}
                        </td>
                        <td
                          className="align-middle"
                          onClick={() =>
                            history.push(`/ecommerce/stores/${store?._id}`)
                          }
                        >
                          {store.total_products}
                        </td>
                        <td
                          className="align-middle"
                          onClick={() =>
                            history.push(`/ecommerce/stores/${store?._id}`)
                          }
                        >
                          {store.status === "Hoạt động" ? (
                            <Badge color="green">{store.status}</Badge>
                          ) : store.status === "Tạm dừng" ? (
                            <Badge color="red">{store.status}</Badge>
                          ) : (
                            <Badge color="yellow">{store.status}</Badge>
                          )}
                          {/* <Badge color="green">{store.status}</Badge> */}
                        </td>

                        <td
                          className="align-middle"
                          onClick={() =>
                            history.push(`/ecommerce/stores/${store?._id}`)
                          }
                        >
                          {ConvertTime(
                            store.updated_at
                              ? store.updated_at
                              : store.created_at
                          )}
                        </td>
                        <td>
                          <IconButton
                            margin={5}
                            icon={NewGridItemIcon}
                            onClick={() =>
                              history.push(`/ecommerce/stores/${store?._id}`)
                            }
                          >
                            {" "}
                          </IconButton>
                          <IconButton
                            margin={5}
                            icon={ChangesIcon}
                            onClick={() => handleStatus(store?._id)}
                          ></IconButton>
                          <IconButton
                            margin={5}
                            icon={EditIcon}
                            onClick={() => handleEditStore(store?._id)}
                          ></IconButton>
                          <IconButton
                            margin={5}
                            icon={TrashIcon}
                            onClick={() => handleDelete(store?._id)}
                          ></IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PagingCustom
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  total={totalItem}
                ></PagingCustom>
              </div>
            </div>
          </>
        )
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}

export default Stores;
