import React, { Component, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Loading from "../ui-elements/Loading";
import { urlFileStorage } from "../api/utilities";
import "../../assets/css/active.css";
import {
  AddIcon,
  ApplicationsIcon,
  Avatar,
  CalendarIcon,
  CircleArrowRightIcon,
  DashboardIcon,
  EditIcon,
  FeedIcon,
  GraphIcon,
  HomeIcon,
  Icon,
  LogOutIcon,
  Menu,
  OneToManyIcon,
  PaperclipIcon,
  PeopleIcon,
  PieChartIcon,
  Popover,
  Position,
  SendToIcon,
  ShoppingCartIcon,
  TrendingUpIcon,
  Dialog,
  Pane,
  TextInputField,
  Button,
  Table,
  ArrowRightIcon,
  EmptyState,
  SearchIcon,
  toaster,
  Text,
} from "evergreen-ui";

import { checkTokenLogout } from "../auth";

export function Header(props) {
  const [showModal, setShowModal] = useState(false);
  const [showModalAccount, setShowModalAccount] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/signin";
  };
  const closeMenu = (e) => {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  };

  const toggleHeaderMenu = (e) => {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  };

  const componentDidUpdate = (prevProps) => {
    if (props.location !== prevProps.location) {
      document.querySelector("body").classList.remove("az-header-menu-show");
    }
  };
  const isPathActive = (path) => {
    return props.location.pathname.startsWith(path);
  };

  const history = useHistory("");
  const token = localStorage.getItem("token");

  // get account
  const [accountInfo, setAccountInfo] = useState({});

  useEffect(() => {
    if (token) {
      const url = "http://v2.nextify.vn/accounts";
      const method = "GET";
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios({
        method: method,
        url: url,
        headers: headers,
      })
        .then((res) => {
          const j_response = res.data;
          setAccountInfo(j_response);
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === undefined || status === 401) {
            checkTokenLogout(history);
          }
        });
    }
  }, []);
  // ===

  // get menu
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (token) {
      const url = `http://v2.nextify.vn/menu`;
      const method = "GET";
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios({
        method: method,
        url: url,
        headers: headers,
      })
        .then((res) => {
          const j_response = res.data;
          setMenu(j_response.data);
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === undefined || status === 401) {
            checkTokenLogout(history);
          }
        });
    }
  }, []);

  // ===

  // get package
  const [menuIds, setMenuIds] = useState();

  useEffect(() => {
    if (token) {
      var id = accountInfo?.package_id;
      if (id === undefined) {
        id = "63032d82015b0930b2aa6426";
      }

      const url = `http://v2.nextify.vn/packages/${id}`;
      const method = "GET";
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios({
        method: method,
        url: url,
        headers: headers,
      })
        .then((res) => {
          const j_response = res.data;
          if (j_response.info?.menu_ids) {
            setMenuIds(j_response.info?.menu_ids);
          } else {
            setMenuIds([]);
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === undefined || status === 401) {
            checkTokenLogout(history);
          }
        });
    }
  }, [accountInfo]);
  // ===

  const getIcon = (icon) => {
    switch (icon) {
      case "HomeIcon":
        return HomeIcon;
      case "PeopleIcon":
        return PeopleIcon;
      case "CalendarIcon":
        return CalendarIcon;
      case "TrendingUpIcon":
        return TrendingUpIcon;
      case "ShoppingCartIcon":
        return ShoppingCartIcon;
      case "OneToManyIcon":
        return OneToManyIcon;
      case "SendToIcon":
        return SendToIcon;
      case "PieChartIcon":
        return PieChartIcon;
      default:
        return HomeIcon;
    }
  };

  const RenderHeader = ({ menuIds, menu }) => {
    if (menuIds) {
      if (menuIds.length) {
        return menuIds.map((item, index) => {
          var main = menu.find((m) => m.menu_id === item.main);
          var subs = [];
          item.sub.map((s) => {
            var subItem = menu.find((m) => m.menu_id === s);
            if (subItem) {
              subs.push(subItem);
            }
          });

          var htmlString = "";
          if (subs.length) {
            if (subs[0]?.url) {
              var urlMain = "/" + subs[0]?.url.split("/")[1];
              htmlString = (
                <li className="nav-item dropdown" key={"head" + index}>
                  <Dropdown
                    className={
                      isPathActive(urlMain) ? "nav-item active" : "nav-item"
                    }
                  >
                    <Dropdown.Toggle as={"a"} className="nav-link with-sub">
                      <Icon icon={getIcon(main.icon)} marginRight={5} />
                      {main.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ "margin-top": "15px" }}>
                      {subs.map((itemsub, idx) => (
                        <Link
                          to={itemsub.url}
                          key={index + "sub" + idx}
                          className="dropdown-item"
                        >
                          {itemsub.name}
                        </Link>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              );
            }
          } else {
            var urlMain = main?.url;
            if (urlMain) {
              htmlString = (
                <li
                  className={
                    isPathActive(urlMain) ? "nav-item active" : "nav-item"
                  }
                  key={"head" + index}
                >
                  <Link
                    to={urlMain}
                    className={
                      isPathActive(urlMain) ? "nav-link active" : "nav-link"
                    }
                  >
                    <Icon icon={getIcon(main.icon)} marginRight={5} />{" "}
                    {main.name}
                  </Link>
                </li>
              );
            }
          }
          return htmlString;
        });
      }
    }

    return <Pane></Pane>;
  };

  // lay danh sach nguoi dung
  const [users, setUser] = useState([]);
  useEffect(() => {
    if (token) {
      const url = "http://v2.nextify.vn/accounts/referrer";
      const method = "GET";
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios({
        method: method,
        url: url,
        headers: headers,
      })
        .then((res) => {
          const j_response = res.data;
          const users = j_response.data;
          // setUser(users);
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 401) {
            checkTokenLogout(history);
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    }
  }, []);
  // ===

  // get name role
  const getNameRole = (id) => {
    var findRole = roles.find((item) => item.value === id);
    var name = "";
    if (findRole) {
      name = findRole.label.split(":")[0];
    }
    return name;
  };
  // ===

  // lay danh sach quyen
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (token) {
      const url = "http://v2.nextify.vn/role/list_role";
      const method = "GET";
      const headers = {
        Authorization: "Bearer " + token,
      };
      axios({
        method: method,
        url: url,
        headers: headers,
      })
        .then((res) => {
          const j_response = res.data;
          const roles = j_response.data;
          const rolesOption = roles.map((item) => ({
            label: `${item.display_name}: ${item.description}`,
            value: item._id,
          }));
          setRoles(rolesOption);
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status === 401) {
            checkTokenLogout(history);
          } else {
            toaster.danger("Có lỗi xảy ra");
          }
        });
    }
  }, []);
  // ===

  // login accsub
  const handleLogin = (acc) => {
    if (acc) {
      const url = "http://v2.nextify.vn/auth";
      const method = "POST";

      let bodyFormData = new FormData();
      bodyFormData.append("username", acc.username);
      bodyFormData.append("password", acc.password);
      bodyFormData.append("encode", true);

      axios({
        method: method,
        url: url,
        data: bodyFormData,
      })
        .then((res) => {
          const j_response = res.data;
          if (j_response.access_token) {
            localStorage.setItem("token", j_response.access_token);
            window.location.reload();
            setShowModalAccount(false);
          } else {
            toaster.danger("Đăng nhập không thành công, vui lòng thử lại.");
          }
        })
        .catch((error) => {
          var status = error.response?.status;
          if (status == 422) {
            toaster.warning("Vui lòng điền đầy đủ thông tin");
          } else {
            toaster.danger("Đăng nhập không thành công, vui lòng thử lại.");
          }
        });
    } else {
      toaster.danger("Đăng nhập không thành công, vui lòng thử lại.");
    }
  };
  // ===

  return (
    <div>
      <Dialog
        isShown={showModalAccount}
        title="Chọn tài khoản đăng nhập"
        onCloseComplete={() => setShowModalAccount(false)}
        width={850}
        hasFooter={false}
      >
        <Pane marginY={24}>
          {users !== undefined ? (
            users.length ? (
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Tên đăng nhập</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Quyền</Table.TextHeaderCell>
                  <Table.TextHeaderCell></Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {users.map((item) => (
                    <Table.Row key={item._id} minHeight={80} height="auto">
                      <Table.Cell className="my-2">{item.username}</Table.Cell>
                      <Table.Cell>
                        <Text size={300}>{item.email}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text size={300}>{getNameRole(item.role_id)}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          type="button"
                          appearance="primary"
                          onClick={() => handleLogin(item)}
                        >
                          Đăng nhập
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <EmptyState
                background="dark"
                title=""
                orientation="vertical"
                icon={<SearchIcon color="#C1C4D6" />}
                iconBgColor="#EDEFF5"
                description="Bạn chưa có danh sách tham gia nào."
              />
            )
          ) : (
            <Loading></Loading>
          )}
        </Pane>
      </Dialog>

      <Dialog
        isShown={showModal}
        title="Tìm kiếm"
        onCloseComplete={() => setShowModal(false)}
        hasFooter={false}
      >
        <TextInputField label="" inputHeight={40}></TextInputField>
        <Pane display="flex" justifyContent="right" marginBottom={24}>
          <Pane>
            <Button marginRight={16} appearance="primary">
              Tìm kiếm
            </Button>
            <Button appearance="minimal" onClick={() => setShowModal(false)}>
              Đóng
            </Button>
          </Pane>
        </Pane>
      </Dialog>
      <div className="az-header az-header-primary">
        <div className="az-header-top">
          <div className="container d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="az-header-left">
                <a href="/" className="az-logo">
                  CRMX
                </a>
                <a
                  id="azMenuShow"
                  onClick={(event) => toggleHeaderMenu(event)}
                  className="az-header-menu-icon d-lg-none"
                  href="/"
                >
                  <span>CRMX</span>
                </a>
              </div>
              <div className="az-header-center">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Tìm kiếm..."
                />
                <button className="btn">
                  <i className="fas fa-search"></i>
                </button>
              </div>
              <div className="az-header-right">
                <div className=" dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={"a"}
                      className="navbar-dropdown-account-wrapper"
                    >
                      {accountInfo?.photo ? (
                        <img
                          className="img-avatar-40"
                          src={urlFileStorage + accountInfo?.photo}
                          alt=""
                        ></img>
                      ) : (
                        <Avatar
                          size={40}
                          name={accountInfo?.name}
                          round="true"
                        ></Avatar>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        "margin-top": "15px",
                        width: "16rem",
                        opacity: 1,
                        transform: "translateY(10px) translateY(-10px)",
                        transition:
                          "transform 300ms ease 0s opacity 300ms ease 0s",
                      }}
                    >
                      <Link to="/profile" className="dropdown-item">
                        Tài khoản
                      </Link>
                      <Link to="/employee" className="dropdown-item">
                        Nhân viên
                      </Link>
                      <Link to="/activity" className="dropdown-item">
                        Hoạt động
                      </Link>

                      <Link to="/templates" className="dropdown-item">
                        Templates
                      </Link>
                      <Link to="/integrations" className="dropdown-item">
                        Tích hợp
                      </Link>
                      <Link
                        to="javascript:"
                        className="dropdown-item"
                        onClick={handleLogout}
                      >
                        Đăng xuất
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <Dropdown>
                  <Dropdown.Toggle as={"div"} style={{ cursor: "pointer" }}>
                    {accountInfo?.photo ? (
                      <img
                        className="img-avatar-40"
                        src={urlFileStorage + accountInfo?.photo}
                        alt=""
                      ></img>
                    ) : (
                      <Avatar
                        size={40}
                        name={accountInfo?.name}
                        round="true"
                      ></Avatar>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link to="/profile" className="dropdown-item">
                      Tài khoản
                    </Link>
                    <Link to="/employee" className="dropdown-item">
                     Nhân viên
                    </Link>
                    <Link to="/activity" className="dropdown-item">
                      Hoạt động
                    </Link>

                    <Link to="/templates" className="dropdown-item">
                      Templates
                    </Link>
                    <Link to="/integrations" className="dropdown-item">
                      Tích hợp
                    </Link>
                    <Link
                      to="javascript:"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      Đăng xuất
                    </Link>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
            </div>
          </div>
        </div>
        <div className="az-header-bottom">
          <div className="container d-block">
            <div className="az-header-menu">
              <div className="az-header-menu-header">
                <Link to="/home/dashboard" className="az-logo">
                  <span></span>
                </Link>
                <a
                  href="#/"
                  onClick={(event) => toggleHeaderMenu(event)}
                  className="close"
                >
                  &times;
                </a>
              </div>
              <ul className="nav">
                <RenderHeader menuIds={menuIds} menu={menu}></RenderHeader>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
